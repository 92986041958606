



















import {
  defineComponent,
  useRouter,
  useContext,
  computed
} from '@nuxtjs/composition-api'
import { useUser } from '@wemade-vsf/customer';
import { useUiState } from '@wemade-vsf/composables';
import { SfButton } from '@storefront-ui/vue'

export default defineComponent({
  name: 'AccountIcon',
  components: {
    SfButton
  },
  props: {
    iconHeight: {
      type: String,
      default: '1.6875rem'
    },
    iconWidth: {
      type: String,
      default: '1.6875rem'
    }
  },
  setup () {
    const { app, i18n } = useContext()
    const router = useRouter()
    const { isAuthenticated } = useUser()
    const { toggleLoginModal } = useUiState()
    const linkText = computed(() => {
      return isAuthenticated.value
        ? i18n.t('My User Account')
        : i18n.t('Login')
    })

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      } else {
        toggleLoginModal();
      }
    }

    return {
      handleAccountClick,
      isAuthenticated,
      linkText
    }
  }
})
