import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2ef30c56 = () => interopDefault(import('../_templates/pages/checkout/Cart.vue' /* webpackChunkName: "" */))
const _0b9c727b = () => interopDefault(import('../_templates/pages/checkout/Checkout.vue' /* webpackChunkName: "" */))
const _3e9b8f79 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Account.vue' /* webpackChunkName: "" */))
const _7ba6d11a = () => interopDefault(import('../_templates/pages/checkout/Checkout/Order.vue' /* webpackChunkName: "" */))
const _2836ca39 = () => interopDefault(import('../_templates/pages/checkout/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _237f14f3 = () => interopDefault(import('../_templates/pages/search-loop54/Search.vue' /* webpackChunkName: "" */))
const _14b32e2f = () => interopDefault(import('../_templates/pages/customer/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _44e351f4 = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _9a409820 = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _68f24c74 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _164c0f80 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _932bbecc = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _38ea76b4 = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _668d83bf = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _70990b44 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollection.vue' /* webpackChunkName: "" */))
const _3a7f75de = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollectionItem.vue' /* webpackChunkName: "" */))
const _4f8110e9 = () => interopDefault(import('../_templates/pages/faq/Faq.vue' /* webpackChunkName: "" */))
const _2ee9b65d = () => interopDefault(import('../pages/payment-svea/CheckoutConfirmation.vue' /* webpackChunkName: "" */))
const _72a87fca = () => interopDefault(import('../_templates/pages/payment-svea/Checkout.vue' /* webpackChunkName: "" */))
const _fd10208a = () => interopDefault(import('../_templates/pages/blog/BlogPost.vue' /* webpackChunkName: "" */))
const _5928270a = () => interopDefault(import('../_templates/pages/blog/Blog.vue' /* webpackChunkName: "" */))
const _3a214279 = () => interopDefault(import('../_templates/pages/theme/Page.vue' /* webpackChunkName: "" */))
const _2c27a66e = () => interopDefault(import('../_templates/pages/theme/Home.vue' /* webpackChunkName: "" */))
const _ca505eea = () => interopDefault(import('../pages/catalog/Category.vue' /* webpackChunkName: "pages/catalog/Category" */))
const _1824b048 = () => interopDefault(import('../pages/catalog/OldCategory.vue' /* webpackChunkName: "pages/catalog/OldCategory" */))
const _3fd90d72 = () => interopDefault(import('../pages/catalog/Product.vue' /* webpackChunkName: "pages/catalog/Product" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cart",
    component: _2ef30c56,
    name: "checkout-cart"
  }, {
    path: "/checkout",
    component: _0b9c727b,
    name: "checkout",
    children: [{
      path: "konto",
      component: _3e9b8f79,
      name: "checkout-account"
    }, {
      path: "bestilling",
      component: _7ba6d11a,
      name: "checkout-order"
    }, {
      path: "takk",
      component: _2836ca39,
      name: "checkout-thank-you"
    }]
  }, {
    path: "/search",
    component: _237f14f3,
    name: "search"
  }, {
    path: "/customer/reset-password",
    component: _14b32e2f,
    alias: "/customer/account/createPassword",
    name: "reset-password"
  }, {
    path: "/customer",
    component: _44e351f4,
    meta: {"titleLabel":"Account"},
    name: "customer",
    children: [{
      path: "my-profile",
      component: _9a409820,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile"
    }, {
      path: "addresses-details",
      component: _68f24c74,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details"
    }, {
      path: "addresses-details/create",
      component: _164c0f80,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _932bbecc,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit"
    }, {
      path: "order-history",
      component: _38ea76b4,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history"
    }, {
      path: "order-history/:orderId",
      component: _668d83bf,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order"
    }]
  }, {
    path: "/collection/:slug([^/.]+).html",
    component: _70990b44,
    name: "attributeCollection"
  }, {
    path: "/collection/:collection_slug/:slug+.html",
    component: _3a7f75de,
    name: "attributeCollectionItem"
  }, {
    path: "/faq.html",
    component: _4f8110e9,
    name: "faq"
  }, {
    path: "/sveacheckout/confirmation",
    component: _2ee9b65d,
    name: "sveaCheckoutConfirmation"
  }, {
    path: "/sveacheckout",
    component: _72a87fca,
    name: "sveaCheckout"
  }, {
    path: "/magasinet/:slug+",
    component: _fd10208a,
    name: "blogPost"
  }, {
    path: "/magasinet",
    component: _5928270a,
    name: "blog"
  }, {
    path: "/:slug+",
    component: _3a214279,
    name: "page"
  }, {
    path: "/",
    component: _2c27a66e,
    name: "home"
  }, {
    path: "/catalog/Category",
    component: _ca505eea,
    name: "catalog-Category"
  }, {
    path: "/catalog/OldCategory",
    component: _1824b048,
    name: "catalog-OldCategory"
  }, {
    path: "/catalog/Product",
    component: _3fd90d72,
    name: "catalog-Product"
  }, {
    path: "/payment-svea/CheckoutConfirmation",
    component: _2ee9b65d,
    name: "payment-svea-CheckoutConfirmation"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
