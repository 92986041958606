












import {
  defineComponent,
  computed,
  useFetch
} from '@nuxtjs/composition-api'
import { useMegaMenuStore } from '@wemade-vsf/megamenu';

export default defineComponent({
  name: 'MegaMenu',
  props: {
    isVertical: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const menuStore = useMegaMenuStore()
    const items = computed(() => menuStore.items)
    const dynamicComponent = () => import('components/megamenu/MegaMenuItem' + (props.isVertical ? 'Vertical' : '') + '.vue')

    useFetch(async() => {
      if (items.value.length === 0) {
        await menuStore.load()
      }
    })

    return {
      items,
      dynamicComponent
    }
  }
})
