<template>
  <div class="mobile-navigation-bar">
    <div class="mobile-navigation-bar--left">
      <slot name="left">
        <SfButton
          class="sf-button--pure header-button menu-mobile-toggle-button"
          :aria-label="$t('Menu')"
          @click="toggleMobileMenu"
        >
          <wm-icon
            :name="isMobileMenu ? 'x-mark' : 'bars'"
            :label="$t('Menu')"
            width="26"
            height="26"
          />
        </SfButton>        
      </slot>
    </div>
    <HeaderLogo logo="/logoSmallDark.svg" :width="150" :height="63" />
    <div class="mobile-navigation-bar--right">
      <slot name="right" v-bind="{ isMobileMenu, activeItem }">
        <AccountIcon />
        <CartIcon />        
      </slot>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed } from '@nuxtjs/composition-api'
import HeaderLogo from 'components/theme/Header/Logo.vue';
import { SfButton } from '@storefront-ui/vue';
import { useUiState } from '@wemade-vsf/composables';
import { useMegaMenuStore } from '@wemade-vsf/megamenu';
import AccountIcon from 'components/theme/Icons/AccountIcon.vue';
import CartIcon from 'components/theme/Icons/CartIcon.vue';

export default defineComponent({
  name: 'MobileNavigationBar',
  components: {
    HeaderLogo,
    SfButton,
    AccountIcon,
    CartIcon
  },
  setup () {
    const { toggleMobileMenu, isMobileMenuOpen: isMobileMenu } = useUiState()
    const menuStore = useMegaMenuStore()
    const activeItem = computed(() => menuStore.activeItem)

    return {
      activeItem,
      isMobileMenu,
      toggleMobileMenu
    }
  }
})
</script>
<style lang="scss" scoped>
.mobile-navigation-bar {
  --main-menu-icon-button-text-display: none;
  display: flex;
  gap: 2rem;
  height: 52px;
  justify-content: space-between;
  align-items: center;
  background: var(--c-light);
  padding: var(--mobile-navigation-bar-padding, var(--spacer-xs));

  &--right {
    display: flex;
    gap: 0.5rem;
  }
}
</style>