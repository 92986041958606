const moduleOptions = `{"generateRoutes":true,"routes":{"home":"magasinet","prefix":"magasinet/","postPrefix":"","urlSuffix":""},"imageSizes":{"card":{"width":400,"height":412},"featured":{"width":650,"height":413},"detail":{"width":1300,"height":820}}}`;
var plugin_default = (context, inject) => {
  const options = JSON.parse(moduleOptions);
  let wm = context.$wm || {};
  wm.blog = options;
  inject("wm", wm);
};
export {
  plugin_default as default
};
