





























































import {
  defineComponent,
  ref,
  computed,
  onBeforeMount,
  onMounted,
  onBeforeUnmount
} from '@nuxtjs/composition-api'
import { useConfig } from '@wemade-vsf/composables';
import { SfOverlay } from '@storefront-ui/vue'
import HeaderLogo from 'components/theme/Header/Logo.vue';
import { useUiState } from '@wemade-vsf/composables';
import { useSearch } from '@wemade-vsf/search-loop54';
import type { ProductInterface } from '@wemade-vsf/magento-api';
import MegaMenu from 'components/megamenu/MegaMenu.vue';
import CartNavigation from 'components/theme/Header/CartNavigation.vue';
import MobileNavigationBar from 'components/theme/Header/MobileNavigationBar.vue';
import TopNavigation from 'components/theme/Header/TopNavigation.vue';

export default defineComponent({
  name: 'AppHeader',
  components: {
    CartNavigation,
    HeaderLogo,
    MegaMenu,
    MobileNavigationBar,
    SfOverlay,
    TopNavigation,
    SearchBar: () => import('components/search-loop54/SearchBar.vue'),
    SearchResults: () => import(/* webpackPrefetch: true */ 'components/search-loop54/SearchResults.vue'),
    MobileSidebar: () => import('~/components/MobileSidebar.vue'),
    TopBar: () => import('components/theme/Header/TopBar.vue')
  },
  setup () {
    const { 
      isMobileMenuOpen: isMobileMenu,
      toggleMobileMenu
    } = useUiState();
    const {
      isSearchOpen,
      closeSearch
    } = useSearch();
    const productSearchResults = ref<ProductInterface[] | null>(null);

    const headerBar = ref(null);
    const observeIndicator = ref(null)
    let scrollObserver = null;
    const isSticky = ref(false);

    const { config } = useConfig();
    const topBarConfig = computed(() => {
      if (config.value?.storefront?.top_bar_block_enabled && config.value?.storefront?.top_bar_block_identifier) {
        return {
          background: config.value.storefront.top_bar_block_background_color,
          identifier: config.value.storefront.top_bar_block_identifier
        }
      }
      return null
    })

    onBeforeMount(() => {
      if (!process.server && typeof window !== 'undefined' && ('IntersectionObserver' in window)) {
        scrollObserver = new IntersectionObserver(([e]) => {
          headerBar.value.classList.toggle('is-sticky', e.intersectionRatio < 1)
          document.documentElement.classList.toggle('is-sticky', e.intersectionRatio < 1);
        }, { threshold: [1] })
      }
    })

    onMounted(() => {
      if (!process.server && scrollObserver && observeIndicator.value) {
        scrollObserver.observe(observeIndicator.value);
      }
    })

    onBeforeUnmount(() => {
      if (!process.server && scrollObserver) {
        scrollObserver.unobserve(observeIndicator.value)
      }
    })

    return {
      isMobileMenu,
      isSearchOpen,
      isSticky,
      headerBar,
      productSearchResults,
      observeIndicator,
      toggleMobileMenu,
      closeSearch,
      topBarConfig
    }
  }
})
