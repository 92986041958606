















import { useRoute, defineComponent } from '@nuxtjs/composition-api';
import Logo from 'components/theme/Header/Logo.vue';
import LazyHydrate from 'vue-lazy-hydration';
export default defineComponent({
  name: 'CheckoutLayout',
  components: {
    Logo,
    LazyHydrate,
    Notification: () => import(/* webpackPrefetch: true */ 'components/theme/Notification.vue')
  },
  setup () {
    const route = useRoute();

    return {
      route
    }
  }
})
